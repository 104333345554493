// To manage cookies
import Cookies from "js-cookie"
import { renderError } from './render.js'

// Read the cookie lifetime from the .env file
const COOKIE_EXPIRY_MINUTES = process.env.APP_COOKIE_EXPIRY_MINUTES

/**
 * Check for the ability to create cookies
 */
export function checkCookie() {
    var cookieEnabled = (navigator.cookieEnabled) ? true : false

    if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
        document.cookie = "testcookie"
        cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false
    }
    return cookieEnabled
}

/**
 * Sets a cookie with the value of the current USER_ID
 * 
 * @param {string} id USER_ID (User ID) from the initial URL
 */
export function setuserId(USER_ID) {
    // 
    let cookieExpiryTime = new Date(new Date().getTime() + 60 * COOKIE_EXPIRY_MINUTES * 1000)
    if (USER_ID == '') { return console.log(`User ID was not set, was supplied empty`) }
    Cookies.set('USER_ID', USER_ID, { expires: cookieExpiryTime, sameSite: "lax" })
}

/**
 * Sets a cookie with the values of any auth fields
 * 
 * @param {string} values object containing a_field name/value pairs
 */
export function setAuthFields(data) {
    let cookieExpiryTime = new Date(new Date().getTime() + 60 * COOKIE_EXPIRY_MINUTES * 1000)
    var stringofJSON = JSON.stringify(data)
    Cookies.set("AUTH", stringofJSON, { expires: cookieExpiryTime, sameSite: "lax" })
}

/**
 * Refreshes current cookies using current values though updates the expiry time
 */
export function refreshCookies() {
    let cookieExpiryTime = new Date(new Date().getTime() + 60 * COOKIE_EXPIRY_MINUTES * 1000)
    let USER_ID = Cookies.get('USER_ID')
    Cookies.set('USER_ID', USER_ID, { expires: cookieExpiryTime, sameSite: "lax" })
};

/**
 * Shows an alert when the cookie/session has expired
 */
export function cookieTimer() {
    clearTimeout(cookieTimeout)
    let cookieExpiryTimer = (60 * COOKIE_EXPIRY_MINUTES * 1000)
    let cookieTimeout = setTimeout(renderError, cookieExpiryTimer, 'COOKIE_EXPIRED')
}