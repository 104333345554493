    // Handlebars for simple page templates
    const Handlebars = require("handlebars")

    import { gEvent } from "./reporting.js"

    // Setup all error messages from env files
    const MESSAGE_BAD_REQUEST = process.env.APP_MESSAGE_BAD_REQUEST
    const MESSAGE_FORM_EXPIRED = process.env.APP_MESSAGE_FORM_EXPIRED
    const MESSAGE_NOT_FOUND = process.env.APP_MESSAGE_NOT_FOUND
    const MESSAGE_FAILED = process.env.APP_MESSAGE_FAILED
    const MESSAGE_EXPIRED_CODE = process.env.APP_MESSAGE_EXPIRED_CODE
    const MESSAGE_FORM_LOCKUP = process.env.APP_MESSAGE_FORM_LOCKUP
    const MESSAGE_FORM_UNAUTHORIZED = process.env.APP_MESSAGE_FORM_UNAUTHORIZED
    const MESSAGE_FORBIDDEN = process.env.APP_MESSAGE_FORBIDDEN
    const MESSAGE_NO_ID = process.env.APP_MESSAGE_NO_ID
    const MESSAGE_COOKIE_EXPIRED = process.env.APP_MESSAGE_COOKIE_EXPIRED
    const MESSAGE_DEFAULT = process.env.APP_MESSAGE_DEFAULT

    /**
     * This renders handlebars partials into the target container
     *
     * @param {*} data The object containing the data to be used
     * @param {*} TemplateID The template that is to be used to render the output
     * @param {*} TargetID The HTML element ID that will receive the output
     */
    export function renderTemplate(data, TemplateID, TargetID) {

        // grab the data object
        let TEMPLATE_DATA = data
        if (TEMPLATE_DATA == undefined) { return console.log("TEMPLATE_DATA not supplied") }

        // grab the content of the template
        let TEMPLATE_CONTENT = $(TemplateID).html()
        if (TEMPLATE_CONTENT == undefined) { return console.log("TEMPLATE_CONTENT not found") }

        // Find the target we will inject to
        let TEMPLATE_TARGET = $(TargetID)
        if (TEMPLATE_TARGET == undefined) { return console.log("TEMPLATE_TARGET not found") }

        // Run handlebars over the template - this will prime it with user info
        let TEMPLATE_CONTENT_COMPILED = Handlebars.compile(TEMPLATE_CONTENT)
        if (TEMPLATE_CONTENT_COMPILED == undefined) { return console.log("Handlebars compile failed") }

        // Store the results of processing the data through the template
        let TEMPLATE_OUTPUT = TEMPLATE_CONTENT_COMPILED(TEMPLATE_DATA)

        // Inject the template output into the page
        TEMPLATE_TARGET.html(TEMPLATE_OUTPUT)
    }

    /**
     * This allows simple messages to be injected to the app container, handy for debug
     *
     * @param {*} content Allows content to be directly injected to the app container
     * @returns
     */
    export const render = (content) => (document.querySelector("#app").innerHTML = content)

    /**
     * 
     * Renders alert/error messsages to the body or specific targets within the application
     * 
     * @param {*} errorStatus Pass the API error code to use in the case statement
     * @param {*} templateType Either alert_template or error_template (defaults to #alert_template)
     * @param {*} targetID The ID of the target to place the error template output (defaults to #app)
     */
    export function renderError(errorStatus, templateType, targetID) {

        var errorContent = ""
        let useTemplate = templateType || "#alert_template"
        let useTarget = targetID || "#app"

        switch (errorStatus) {
            case 'BAD_REQUEST':
                errorContent = { "error_type": "Missing or invalid values", "error_message": MESSAGE_BAD_REQUEST, "error_details": errorStatus }
                break;
            case 'FORM_EXPIRED':
                errorContent = { "error_type": "Link no longer valid", "error_message": MESSAGE_FORM_EXPIRED, "error_details": errorStatus }
                break;
            case 'NOT_FOUND':
                errorContent = { "error_type": "Record not found", "error_message": MESSAGE_NOT_FOUND, "error_details": errorStatus }
                break;
            case 'FAILED':
                errorContent = { "error_type": "Verification failed", "error_message": MESSAGE_FAILED, "error_details": errorStatus }
                break;
            case 'EXPIRED_CODE':
                errorContent = { "error_type": "Passcode has expired", "error_message": MESSAGE_EXPIRED_CODE, "error_details": errorStatus }
                break;
            case 'FORM_LOCKUP':
                errorContent = { "error_type": "Too many failed attempts", "error_message": MESSAGE_FORM_LOCKUP, "error_details": errorStatus }
                break;
            case 'UNAUTHORIZED':
                errorContent = { "error_type": "Unauthorised attempt", "error_message": MESSAGE_FORM_UNAUTHORIZED, "error_details": errorStatus }
                break;
            case 'FORBIDDEN':
            case 'SERVICE_UNAVAILABLE':
                errorContent = { "error_type": "Item unavailable", "error_message": MESSAGE_FORBIDDEN, "error_details": errorStatus }
                break;
            case 'NO_ID':
                errorContent = { "error_type": "Invalid link", "error_message": MESSAGE_NO_ID, "error_details": "No ID supplied" }
                break;
            case 'COOKIE_EXPIRED':
                errorContent = { "error_type": "Session expired", "error_message": MESSAGE_COOKIE_EXPIRED, "error_details": "Session expired" }
                break;
            default:
                errorContent = { "error_type": "Unknown error occurred", "error_message": MESSAGE_DEFAULT, "error_details": errorStatus }
        }
        renderTemplate(errorContent, useTemplate, useTarget)

        // add event to GA
        gEvent(errorStatus, "General errors", errorContent.error_type)
    }