// To manage cookies
import Cookies from "js-cookie"
// Handlebars for simple page templates
const Handlebars = require("handlebars")

// Create some constants to hold values
export const BASEURL = window.location.protocol + "//" + window.location.hostname + "/forms/"
export const CLIENTID = process.env.APP_CLIENTID
export const ORIGINATINGAPP = process.env.APP_ORIGINATINGAPP
export const GOOGLEID = process.env.APP_GOOGLEID

/**
 * Will return a validly constructed Forms1/Forms2 endpoint URL
 * Use to save form values (Forms1 only), getData from a user record, postData to save, getDoc from a repository, OTP operations
 * @param {string} [urlType] save, getData, postData, getDoc, reqOTP or verifyOTP
 * @param {string} [docType] Required if retrieving a secured document
 * @param {string} [otpCode] Required if verifying an OTP value
 * @returns {string}
 */

// Build URLs to endpoints for common functions
export function formURL(urlType, docType, otpCode) {

    let BASE = BASEURL + Cookies.get('USER_ID')
    let AUTH_FIELDS = Cookies.get('AUTH')
    let AUTH_FIELDS_PARAMS = ""

    // Add a loop through all auth fields in cookie to get secure data. This is added to some urls below
    if (!!AUTH_FIELDS) {

        AUTH_FIELDS = JSON.parse(AUTH_FIELDS)
        for (var key in AUTH_FIELDS) {
            if (AUTH_FIELDS.hasOwnProperty(key)) {
                AUTH_FIELDS_PARAMS = AUTH_FIELDS_PARAMS + "&" + key + "=" + AUTH_FIELDS[key]
            }
        }
    }

    var urls = {
        save: window.location.protocol + "//" + window.location.hostname + "/save",
        getData: `${BASE}?client=${CLIENTID}`,
        getSecureData: `${BASE}?client=${CLIENTID}&${AUTH_FIELDS_PARAMS}`,
        postData: `${BASE}`,
        getDoc: `${BASE}/documents/${docType}?client=${CLIENTID}&${AUTH_FIELDS_PARAMS}`,
        reqOTP: `${BASE}/otp`,
        verifyOTP: `${BASE}/otp/${otpCode}?client=${CLIENTID}`,
    }
    return urls[urlType] || console.log(`Error: urlType "${urlType}" not found`)
}

/**
 * 
 * @param {*} toState The desired state of the button or link (disabled/enable)
 * @param {*} button The button to affect
 */

export function switchButtonState(toState, button) {
    if (toState === 'enable') {
        // enable buttons or links
        if ($(button).is("a")) {
            $(button).removeClass("disabled").attr("aria-disabled", false).find(".spinner-border").addClass("d-none")
        } else {
            $(button).attr("disabled", false).find(".spinner-border").addClass("d-none")
        }
    } else {
        // disable buttons or links
        if ($(button).is("a")) {
            $(button).addClass("disabled").attr("aria-disabled", true).find(".spinner-border").removeClass("d-none")
        } else {
            $(button).attr("disabled", true).find(".spinner-border").removeClass("d-none")
        }
    }
}

/**
 * 
 * @param {*} form Target form to convert to JSON
 * @returns A JSON object filled with forms values, take care when using form items that return an array
 */
export function convertFormToJSON(form) {
    return $(form)
        .serializeArray()
        .reduce(function(json, { name, value }) {
            json[name] = value
            return json
        }, {})
}

/**
 * 
 * @returns true if Samsung internet is detected
 */
// export function isSI() {
//     var ua = navigator.userAgent
//     var is_si = navigator.userAgent.match(/SamsungBrowser/i)
//     return is_si
// }

/**
 * 
 * @returns true if IE is detected
 */
// export function isIE() {
//     var ua = navigator.userAgent
//     var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1
//     return is_ie
// }

/**
 * Will submit a form if enter key detected
 */
// export function detectEnterKey() {
//     $(".watch-me").on('keyup keypress', function(e) {
//         var keyCode = e.keyCode || e.which
//         if (keyCode === 13) {
//             // e.preventDefault();
//             $(".click-me").trigger("click")
//             console.log('Enter detected')
//             return false
//         }
//     })
// }

/*******************************************
 *
 *
 *  Handlebars Helpers
 *
 *
 ********************************************/

/**
 * Test using an operator, eg
 * {{#iff or_xxx '==' '1'}} ...
 * {{else}} ...
 * {{/iff}}
 */
Handlebars.registerHelper("iff", function(a, operator, b, opts) {
    var bool = false;
    switch (operator) {
        case "==":
            bool = a == b
            break
        case "!=":
            bool = a != b
            break
        case ">":
            bool = a > b
            break
        case "<":
            bool = a < b
            break
        default:
            throw "Unknown operator " + operator
    }
    if (bool) {
        return opts.fn(this)
    } else {
        return opts.inverse(this)
    }
})

/**
 * Test if item 'a' is found found in array 'list'
 * eg. {{#ifIn red rainbow}} ...
 */
Handlebars.registerHelper("ifIn", function(a, list, opts) {
    if (list.indexOf(a) > -1) {
        return opts.fn(this)
    }
    return opts.inverse(this)
})

/**
 * Set a radio button to checked if values match
 * eg. <input type="radio" id="reminders_via_sms" value="SMS" {{checked srw_reminders_via 'SMS'}}>
 */
Handlebars.registerHelper("checked", function(value, test) {
    if (value == undefined) return ""
    return value == test ? "checked" : ""
})

/**
 * match a value to a select field, set to selected if true, eg:
 * {{#select srw_healthfund}}
 * <option value="HBF">HBF</option>
 * {{/select}}
 */
Handlebars.registerHelper("select", function(value, options) {
    var $el = $("<select />").html(options.fn(this))
    $el.find('[value="' + value + '"]').attr({ selected: "selected" })
    return $el.html()
})

/*******************************************
 *
 *
 *  Handlebars Helpers END
 *
 *
 ********************************************/