// Create some constants to hold ENV variables
const GOOGLEID = process.env.APP_GOOGLEID

/**
 * 
 * Reporting is a function used to send page tracking info to Google
 * 
 * @param {*} overidePath Optional path value to override what would normally be sent to google
 */
export function reporting(overidePath) {

    console.log("Reporting called")

    // If GoogleID is supplied, let's track
    if (GOOGLEID != "") {

        // A supplied path can overide the value used to send to Google (useful to hide recipient's pURL etc)
        if (overidePath) {
            console.log(`overidePath = ${overidePath}`)
            var pagePath = overidePath
            var pageUrl = `${window.location.protocol}//${window.location.hostname}${overidePath}`

            // Else, no path was passed in, just use the current path
        } else {
            console.log(`overidePath = ${overidePath} [should be empty]`)
            var pagePath = window.location.pathname
            var pageUrl = window.location.href
        }

        gtag("event", "page_view", {
            "page_title": document.title,
            "page_path": pagePath,
            "pageUrl": pageUrl
        })
    }
}

/**
 * 
 * Reporting is a function used to send page tracking info to Google
 * 
 * @param {*} action string - The value that will appear as the event action in Google Analytics Event reports.
 * @param {*} categoryName string - The category of the event.
 * @param {*} labelName string - The label of the event.
 */
export function gEvent(action, categoryName, labelName) {
    if (GOOGLEID != "") {
        gtag("event", action, {
            "event_category": categoryName,
            "event_label": labelName
        })
    }
}


/**
 * Google's gtag function for communicating data to Google
 */
function gtag() {
    dataLayer.push(arguments)
    console.log("gtag content =", arguments)
}


/**
 * 
 * gUser is a function to enable cross device tracking at Google
 * 
 * @param {*} userId A non personal identifier (account number etc) 
 */
export function gUser(userId) {
    // gTag("config", GOOGLEID, { "user_id": GOOGLE_USERID })
    console.log(`Set Google user_id to ${userId}`)
    gtag("set", { "user_id": userId }); // Set the user ID using signed-in user_id for tracking across devices
    console.log(`Set custom Google ID cust_id to ${userId}`)
    gtag("set", { "cust_id": userId }); // Set the cust ID using signed-in user_id for use in google reporting

}