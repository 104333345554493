// Axios to help with data+endpoints
const axios = require("axios")

// form validation library
import { switchButtonState, convertFormToJSON, formURL, endpointError } from "./utils.js"
import { renderError } from "./render.js"
import { router } from "../app"
import { gEvent } from "./reporting.js"

// the main two functions needed to setup this view
export function unsubscribepage() {

    // listen to button and submit on click
    $("#submit-button").click(function(event) {

        //prevent default form action
        event.preventDefault();
        submit_form()
    });
}


function submit_form() {

    // disable button to throttle submits
    switchButtonState("disable", "#submit-button")

    // grab values and populate a new JSON obj
    const form = $("#unsubscribe-form")

    // call a function to convert form fields//values to JSON
    const FormJSON = convertFormToJSON(form)

    // pass JSON object to endpoint via axios
    submitToEndpoint(FormJSON)
}

function submitToEndpoint(json) {

    // setup axios to post the data to the endpoint
    axios.post(formURL("save"), json)
        .then(function(response) {
            // if the endpoint replies 'all good' use axios to route to OTP and enable button
            switchButtonState("enable", "#submit-button")

            // add event to GA
            gEvent("Unsubscribe submit success", "Unsubscribe submit", "Unsubscribe submit success label")

            router.navigate("unsubscribed")
        })
        .catch(function(error) {
            // show error if failed and enable button
            switchButtonState("enable", "#submit-button")
            console.log(error)

            // add event to GA
            gEvent("Form submit failed", "Form submit", "Form submit failed label")

            // show relevant warning
            renderError(error.response.data.code, "#error_template", "#error_target")
        })
}